import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin } from 'antd';
const contentStyle: React.CSSProperties = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
    minHeight:'600px'
  };

  const content = <div style={contentStyle} />;

const App: React.FC = () => (
    <>
         <div style={{width:'1200px',margin:'0 auto',}}>
         <Spin tip="Loading" >
        {content}
      </Spin>
         </div>
    </>
);

export default App;