import React from 'react';
import ReactDOM from 'react-dom/client';
import { MyRouters } from './router/Router';
import  {HashRouter} from "react-router-dom"
import store from "./redux"
import {Provider} from "react-redux"
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <Provider store={store}>
  <HashRouter>
   <Provider store={store}>
   <MyRouters></MyRouters>
   </Provider>
  </HashRouter>
  // </Provider>
 
);
