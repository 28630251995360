import { lazy,Suspense } from "react"
import {Navigate} from "react-router-dom"
import F404 from "../components/F404"
import Loading from "../components/Loading"

//懒加载
let Home  = lazy(()=>import("../views/Home"))
let Course  = lazy(()=>import("../views/Course"))
let CourseDetail  = lazy(()=>import("../views/CourseDetail"))
let User  = lazy(()=>import("../views/User"))
let VideoPlayer = lazy(()=>import("../views//VideoPlayer"))
let News = lazy(()=>import('../views/News'))
let NewsInfo = lazy(()=>import("../views/NewsInfo"))
let Appdownload  = lazy(()=>import("../views/Appdownload"))



let BaseNews = lazy(()=>import("../components/User/RightCon/BaseNews"))
let MyStudy = lazy(()=>import("../components/User/RightCon/MyStudy"))
let Collect = lazy(()=>import("../components/User/RightCon/Collect"))
let ResPassword = lazy(()=>import("../components/User/RightCon/ResPassword"))
let Real = lazy(()=>import("../components/User/RightCon/Real"))




//理由表
export let elements:MRoutes = [
    {
        path:"/",//路径重写
        element:<Navigate to={"/index"}></Navigate>,
        author:false,
    },
    {
        path:"/user",//路径重写
        element:<Navigate to={"/user/basenews"}></Navigate>,
        author:false,
    },
    {
        path:"*",//错误访问
        element:<F404/>,
        author:false,
    },
    {
        path:"/index",//首页
        element:<Suspense fallback={<Loading/>}><Home/></Suspense>,
        author:false,
    },
    
    {
        path:'/course',//课程中心页
        element:<Suspense fallback={<Loading/>}><Course/></Suspense>,
        author:false,
    },
    {
        path:'/coursedetail',//课程详情页
        element:<Suspense fallback={<Loading/>}><CourseDetail/></Suspense>,
        author:false,
    },
    {
        path:'/user',//个人中心页
        element:<Suspense fallback={<Loading/>}><User/></Suspense>,
        author:true,
        children:[
            {
                path:'basenews',//基本信息页
                element:<Suspense fallback={<Loading/>}><BaseNews/></Suspense>,
                author:true,
            },
            {
                path:'study',//我的学习页
                element:<Suspense fallback={<Loading/>}><MyStudy/></Suspense>,
                author:true,
            },
            {
                path:'collect',//我的收藏页
                element:<Suspense fallback={<Loading/>}><Collect/></Suspense>,
                author:true,
            },
            {
                path:'respassword',//修改密码页
                element:<Suspense fallback={<Loading/>}><ResPassword/></Suspense>,
                author:true,
            },
            {
                path:'real',//实名认证
                element:<Suspense fallback={<Loading/>}><Real/></Suspense>,
                author:true,
            },
        ]
    },
    {
        path:'/video',//视频播放页
        element:<Suspense fallback={<Loading/>}><VideoPlayer/></Suspense>,
        author:true,
    },
    {
        path:'/news',//咨询页
        element:<Suspense fallback={<Loading/>}><News/></Suspense>,
        author:false,
    },
    {
        path:'/newsinfo',//资讯详情页
        element:<Suspense fallback={<Loading/>}><NewsInfo/></Suspense>,
        author:false,
    },
    {
        path:'/down',//app下载页
        element:<Suspense fallback={<Loading/>}><Appdownload/></Suspense>,
        author:false,
    },
]