import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import "./SpotCheck.css"
import { GetNew, GetSectionUnCheck } from "./api/SpotCheck"
import { Descriptions, Card, Table, message, Image, Tooltip, Tabs, Alert, Row, Col, Collapse } from 'antd';
import type { TableProps } from 'antd';
import type { TabsProps } from 'antd';
import formateTime from "./utils/formateTime";
import { second } from "./utils/second"
// @ts-nocheck
export default function App() {
  const location = useLocation()
  const navigate = useNavigate()
  const [course, setCourse] = useState<any>({})
  const [user, setUser] = useState<any>({})
  const [unchecks, setUnChecks] = useState<any>([])
  const [unChecksList, setUnCheckList] = useState<any>([])
  const [keys,setKeys] = useState(0)

  const columns: TableProps<any>['columns'] = [
    {
      title: '小节名称',
      dataIndex: 'section_name',
      key: 'section_name',
    },
    {
      title: '小节时长',
      dataIndex: 'section_video_time',
      key: 'section_video_time',
      render: (_, row) => {
        return (
          <>
            {
              formateTime(row.video_time)
            }
          </>
        )

      }
    },
    {
      title: '开始时间',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: '结束时间',
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
    {
      title: '学习进度',
      dataIndex: 'learn_process',
      key: 'learn_process',
      render: (_, row) => <span>{Math.ceil((row.learn_progress || 0) * 100)}%</span>
    },
    {
      title: '学习时长',
      render: (_, row) => <span>{second(row.play_time || 0)}</span>
    },

  ];

  const columns2: TableProps<any>['columns'] = [
    {
      title: <div style={{ textAlign: 'center', width: '100%' }}>课程名称</div>,
      dataIndex: 'course_name',
      key: 'course_name',
    },
    {
      title: <div style={{ textAlign: 'center', width: '100%' }}>小节名称</div>,
      dataIndex: 'section_name',
      key: 'section_name',
    },
    {
      title: <div style={{ textAlign: 'center', width: '100%' }}>学习时长</div>,
      dataIndex: 'video_time',
      key: 'video_time',
      render: (_, row) => {
        return (
          <>
            <div>
              {formateTime(row.video_time)}
            </div>
          </>
        )
      }
    },
    {
      title: <div style={{ textAlign: 'center', width: '100%' }}>开始时间</div>,
      key: 'created_at',
      dataIndex: 'created_at',
    },
    {
      title: <div style={{ textAlign: 'center', width: '100%' }}>结束时间</div>,
      key: 'updated_at',
      dataIndex: 'updated_at',
    },

  ];

  const columns3: TableProps<any>['columns'] = [

    {
      title: <div style={{ width: '100%' }}>小节名称</div>,
      dataIndex: 'section_name',
      key: 'section_name',
    },
    {
      title: <div style={{ width: '100%' }}>学习时长</div>,
      dataIndex: 'video_time',
      key: 'video_time',
      render: (_, row) => {
        return (
          <>
            <div>
              {formateTime(row.video_time)}
            </div>
          </>
        )
      }
    },
    {
      title: <div style={{ width: '100%' }}>开始时间</div>,
      key: 'created_at',
      dataIndex: 'created_at',
    },
    {
      title: <div style={{ width: '100%' }}>结束时间</div>,
      key: 'updated_at',
      dataIndex: 'updated_at',
    },

  ]

  const handChange = (record: any, index: number) => {
    if (unChecksList[index] && unChecksList[index].length > 0) return
    GetSectionUnCheck({
      user_id: user.user_id,
      section_id: record.section_id
    }).then(res => {
      if (res.data.data.code == 200) {
        let newArr = JSON.parse(JSON.stringify(unChecksList))
        newArr[index] = res.data.data.unchecks
        setUnCheckList(newArr)
        setKeys(keys+1)
      }

    })


  };

  useEffect(() => {
    const data = location.search.replace("?data=", '')
    if (data) {
      GetNew({
        data
      }).then(res => {
        if (res.data.data.code == 200) {

          if (!res.data.data.course.classname && !res.data.data.user.realname) {
            message.error('网络错误')

            setTimeout(() => {
              navigate('/index')
            }, 1000)

            return
          }

          setCourse(res.data.data.course)
          setUser(res.data.data.user)
          setUnChecks(res.data.data.course.unchecks)
        } else {
          message.error('暂无内容！')

          setTimeout(() => {
            navigate('/index')
          }, 1000)
        }
      })
    } else {
      message.error('网络错误')

      setTimeout(() => {
        navigate('/index')
      }, 1000)
    }

  }, [])


  return (
    <div>

      <div className="check_box">
        <div className="check">
          <Card>
            <Descriptions title="学生信息" size="middle">
              <Descriptions.Item span={0.5} label="真实姓名">{user && user.realname && user.realname}</Descriptions.Item>
              <Descriptions.Item label="性别">{user && user.gender && user.gender}</Descriptions.Item>
              <Descriptions.Item label="手机号">{user && user.phone && user.phone}</Descriptions.Item>
              <Descriptions.Item label="身份证号">{user && user.idcard && user.idcard}</Descriptions.Item>
            </Descriptions>
          </Card>

          <Tabs
            style={{ paddingLeft: '5px' }}
            defaultActiveKey="1"
            items={[
              {
                label: '学习记录',
                key: '1',
                children: <>
                  {
                    course && course.class_name && <Card style={{ marginTop: '20px' }}>
                      <div className="check_top">
                        <div className="check_top_title">
                          <span>班级名称：{course.class_name}【{course.class_start}~{course.class_end}】</span>
                        </div>
                        <div className="check_top_class">
                          总时长： {formateTime(Number(course.study_total))}
                        </div>
                      </div>

                      <Table columns={columns} dataSource={course.sections} style={{ marginTop: '20px' }} pagination={false} indentSize={20}
                        expandable={{
                          defaultExpandAllRows: true,
                          expandedRowRender: (record, index, indent, expanded) => {
                            return (
                              <>
                                {
                                  /* eslint-disable */
                                  // @ts-ignore
                                  <Image.PreviewGroup >
                                    {

                                      record?.face?.map((item2: any, key: any) => <Tooltip key={key} placement="topLeft" title={item2.created_at}>
                                        <div style={{ display: 'inline-block', border: '1px solid #eee', margin: '20px', color: '#333', fontSize: '12px', padding: '10px', }}>

                                         <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                         {
                                            // @ts-ignore
                                            <Image  preview={item2.status !== 1} height={120} src={'https://hande.obs.dualstack.cn-north-4.myhuaweicloud.com/' + item2.face_url} style={{ padding: '5px' }} />
                                          }
                                         </div>
                                          <div>
                                            已播放：{formateTime(item2.play_time)}
                                          </div>
                                          <div>
                                            拍照时间：{item2.created_at}
                                          </div>
                                          <div>
                                            拍照类型：{item2.is_pause == 1 ? '暂停抓拍' : (item2.play_time == 0 ? '开始抓拍' : (record.video_time == item2.play_time ? "结束抓拍" : '随机抓拍'))}
                                          </div>
                                        </div>
                                      </Tooltip>)
                                      // @ts-ignore
                                    }</Image.PreviewGroup>
                                  /* eslint-disable */
                                }
                                {
                                  record.uncheck_num > 0 && <Collapse items={[{
                                    key: index,
                                    label: '审核不通过记录',
                                    children: <Table
                                      columns={columns3}
                                      dataSource={unChecksList[index]}
                                      style={{ marginTop: '20px' }}
                                      pagination={false}
                                      rowKey={'id'}
                                      key={keys}
                                      indentSize={20}
                                      expandable={{
                                        defaultExpandAllRows: true,
                                        expandedRowRender: (record, index, indent, expanded) => {
                                          return (
                                            <>
                                              {
                                                /* eslint-disable */
                                                // @ts-ignore
                                                <Image.PreviewGroup >
                                                  {

                                                    record?.faces?.map((item3: any, key2: any) => <Tooltip key={key2} placement="topLeft" title={item3.created_at}>
                                                      <div style={{ display: 'inline-block', border: '1px solid #eee', margin: '20px', color: '#333', fontSize: '12px', padding: '10px' }}>

                                                      <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                            {
                                                                // @ts-ignore
                                                                <Image  preview={item3.status !== 1} height={120} src={'https://hande.obs.dualstack.cn-north-4.myhuaweicloud.com/' + item3.face_url} style={{ padding: '5px' }} />
                                                              }
                                                            </div>
                                                        <div>
                                                          已播放：{formateTime(item3.play_time)}
                                                        </div>
                                                        <div>
                                                          拍照时间：{item3.created_at}
                                                        </div>
                                                        <div>
                                                          拍照类型：{item3.is_pause == 1 ? '暂停抓拍' : item3.play_time == 0 ? '开始抓拍' : record.video_time == item3.play_time ? "结束抓拍" : '随机抓拍'}
                                                        </div>

                                                      </div>
                                                    </Tooltip>)
                                                    // @ts-ignore
                                                  }</Image.PreviewGroup>
                                                /* eslint-disable */
                                              }
                                            </>
                                          )
                                        },
                                      }}
                                    />,
                                  }]} onChange={() => handChange(record, index)} />
                                }
                              </>
                            )
                          }
                        }}
                      />
                    </Card>
                  }
                  {
                    !course || !course.class_name && <div className="nostudy">
                      未查询到学习记录
                    </div>
                  }

                </>,
              },
              {
                label: '审核不通过记录',
                key: '2',
                children: <>
                  {
                    unchecks.length > 0 && <div>
                      <Alert message={<>
                        <div style={{ textAlign: 'center', color: 'red', fontSize: '16px' }}>
                          学习时长异常或人脸核验不规范（抓拍不规范或无人脸拍照的记录）
                        </div>
                      </>} type="error" />

                      <Table columns={columns2} dataSource={unchecks} rowKey={'id'}
                        expandable={{
                          expandedRowRender: (record) => {
                            return (
                              <>
                                <Row gutter={20}>
                                  {
                                    record.faces.length == 0 && '没有人脸抓拍记录'
                                  }
                                  {
                                    record.faces.map((item3: any, index3: any) => {
                                      return (
                                        <Col span={4.5}>
                                          <Image
                                            key={index3}
                                            style={{ padding: '10px' }}
                                            width={100}
                                            height={100}
                                            src={"https://hande.obs.dualstack.cn-north-4.myhuaweicloud.com/" + item3.face_url}
                                          />
                                          <div style={{ fontSize: '12px' }}>
                                            进度：{formateTime(item3.play_time)}
                                          </div>
                                          <div style={{ fontSize: '12px' }}>
                                            抓拍：{item3.created_at}
                                          </div>
                                          <div style={{ fontSize: '12px' }}>
                                            类型：{item3.play_time == 0 ? '开始抓拍' : record.video_time == item3.play_time ? "结束抓拍" : item3.is_pause == 0 ? '随机抓拍' : '暂停抓拍'}
                                          </div>
                                        </Col>

                                      )
                                    })
                                  }
                                </Row>

                              </>
                            )
                          },
                        }}
                      />


                    </div>
                  }
                  {
                    unchecks.length == 0 && <div className="nostudy">
                      未查询到审核不通过记录
                    </div>
                  }


                </>,
              },

            ]}
          />




        </div>

      </div>


    </div>
  )
}