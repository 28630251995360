import http from "../utils/axios"


//咨询列表
let GetNews = (data:any)=>http({url:"server/v1/news/newsList",data})

//资讯详情
let NewsInfo = (data:any)=>http({url:'server/v1/news/newsDetail',method:'POST',data})


export{
    GetNews,
    NewsInfo
}