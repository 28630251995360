import {Route,Routes} from "react-router-dom"
import {elements} from "./index"
import Author from "./Author"
import App from "../App"
import SpotCheck from "../SpotCheck"

export function MyRouters(){
    return (
        <Routes>
          <Route path="/" element={<App />}>
        {elements.map((item, index) => (
          <Route key={index} path={item.path} element={item.author?<Author OldComponent={item.element} redirctUrl={item.path} />:item.element} >
                  {
                    item.children && item.children.map((item2:any,index2:number)=>(
                      <Route key={index2} path={item2.path} element={item2.author?<Author OldComponent={item2.element} redirctUrl={item.path+"/"+item2.path}  />:item2.element}></Route>
                    ))
                  }
          </Route>
        ))}
        </Route>
        <Route path="/spot_check" element={<SpotCheck />}>

        </Route>
      </Routes>
        
    )
}


//渲染路由表实例  不使用useRoutes是为了进行路由鉴权