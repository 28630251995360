
import { Outlet } from "react-router-dom"
import "./App.css"
import Header from "./components/Header"
import Footer from "./components/Footer"

export default function App() {
    return (
        <div className="body">
            <Header />
            <Outlet />
            <Footer />
        </div>
    )
}