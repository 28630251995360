import axios from "axios"
import { Button, message } from 'antd';
import NProgress from 'nprogress'; // 导入 nprogress 库
import 'nprogress/nprogress.css'; // 导入 nprogress 样式

let nowtime = Date.now()

// let navigate = useNavigate()
// 在路由加载之前显示进度条
function startProgressBar() {
    NProgress.start();

}

// 在路由加载完成后隐藏进度条
function endProgressBar() {
    NProgress.done();
}



// 创建一个配置对象，存储不同地址对应的标识符或键值对
const baseURLs:any = {
    main: "https://apitrain.gdcest.com",
    map: "https://geo.datav.aliyun.com",
    local:'http://localhost:3001'
    // 可以继续添加其他地址
};

// 默认请求地址的标识符
let defaultBaseURL = "main";

// 设置默认请求地址
axios.defaults.baseURL = baseURLs[defaultBaseURL];

// 根据标识符获取请求地址
function getBaseURL(identifier:string) {
    return baseURLs[identifier] || baseURLs[defaultBaseURL];
}

// 使用默认请求地址
axios.defaults.baseURL = getBaseURL(defaultBaseURL);

//默认响应时间
axios.defaults.timeout = 30000



// 请求拦截  主要用来携带token
axios.interceptors.request.use(
    (config) => {
    config.headers['AuthoriZation'] = `Bearer ${localStorage.getItem('token')}`
    startProgressBar()
    return config

},
    (error) => {
        endProgressBar();
        return Promise.reject(error);
    }
)

//响应拦截 主要用来拦截处理登录过期问题
axios.interceptors.response.use(
    (response) => {
        endProgressBar();
        return response;
    },
    (error) => {
        endProgressBar();
        if (error.response) {
            // 在这里处理特定的 HTTP 错误码，如 401（未授权），403（禁止访问）等
            // 可以根据错误码执行相应的操作，比如跳转到登录页面 
            if(error.response.status == 400){

                message.error(error.response.data.message)
                return
            }else if(error.response.status == 401){
                message.error(error.response.data.message)
                localStorage.removeItem('token')
                window.location.href="https://www.gdcest.com/#/index"
                return
            }else if(error.response.status == 422){
                message.error(error.response.data.message)
                return
            }
            else if(error.response.status == 404){
                message.error(error.response.data.message)
                return
            }
        } else if (error.request) {
            // 在这里处理请求发出但没有收到响应的情况，通常表示网络问题
            // navigate("*")
            message.error("图片过大或网络错误！")
            return
            
        } else {
            // 在这里处理其他类型的错误
        }
        return Promise.reject(error);
    }
)


const http = ({
    url,
    method = "GET",
    data,
    baseURLIdentifier = "main"
}: {
    url: string;
    method?: string;
    data?: any;
    baseURLIdentifier?: string;
}) => {
    const baseURL = getBaseURL(baseURLIdentifier);
    url = url + "?time=" + nowtime
    return axios({
        url,
        method,
        data: method !== "GET" ? data : null,
        params: method === "GET" ? data : null,
        baseURL
    });
};



export default http