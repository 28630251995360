import React, { useEffect, useState } from 'react';
import "./index.css"
import { Col, Row, Image, Space } from 'antd';
import { GetHeade } from "../../api/Header"
import { useLocation, useNavigate, } from 'react-router-dom';


export default function App() {

    const navigate = useNavigate()
    const Location = useLocation()
    const [list, setList] = useState<any>([])
    const [ischoose,setIsChoose] = useState(1)

    useEffect(() => {

        GetHeade().then(res => {
            setList(res.data.data.list)
        })


        return () => {

        };
    }, []);

    const GoNav = (route:string,from?:any)=>{
        navigate(route,{state:{from}})
    }



    const goIndex = () => {
        navigate('/index')
    }

    useEffect(()=>{
        switch(Location.pathname){
            case "/index":
                setIsChoose(1)
            return
            case "/course":
                setIsChoose(2)
            return
            case "/coursedetail":
                setIsChoose(2)
            return
            case "/news":
                setIsChoose(3)
            return
            case "/newsinfo":
                setIsChoose(3)
            return
            case "/down":
                setIsChoose(4)
            return
            case "/login":
                setIsChoose(-1)
            return
            case "/user":
                setIsChoose(-1)
            return
            case "/user/basenews":
                setIsChoose(-1)
            return
            case "/user/collect":
                setIsChoose(-1)
            return
            case "/user/study":
                setIsChoose(-1)
            return
            case "/user/respassword":
                setIsChoose(-1)
            return
            case "/video":
                setIsChoose(-1)
            return
        }

    },[Location.pathname])

    return (
        <div className='header_bg'>
            <div className="header">
                <Row style={{height:"100%"}}>
                    <Col span={8}>
                        <Image
                            src={list.pc_logo && list.pc_logo.value}
                            preview={false}
                            style={{ cursor: 'pointer' }}
                            onClick={goIndex}
                            width={300}
                        />
                    </Col>
                    <Col span={16}>
                        <div className='nav'>
                           <div className={ischoose==1?'nav_item':'nav_item2'} onClick={()=>GoNav("/index")}>
                                首页
                            </div>
                            <div className={ischoose==2?'nav_item':'nav_item2'} onClick={()=>GoNav("/course")}>
                                课程资源
                            </div>
                            <div className={ischoose==3?'nav_item':'nav_item2'} onClick={()=>GoNav("/news")}>
                                平台咨询
                            </div>
                            {/* <div className={ischoose==4?'nav_item':'nav_item2'} onClick={()=>GoNav("/down")}>
                                APP下载
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}