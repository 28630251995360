import React,{useEffect,useState} from 'react';
import "./index.css"
import icp from "../../images/cs/icp.png"
import {GetFooter} from "../../api/Footer"
import { Space } from 'antd';
import {GetNews} from "../../api/News"


export default function App(){
    const [list,setList] = useState<any>([])
    const [arr,setNewArr] = useState([])

    useEffect(() => {

        GetFooter().then(res=>{
            setList(res.data.data.list)
        })

        GetNews({
            pageSize: 10,
            category_id:8
    }).then(res=>{
        setNewArr(res.data.data.newsData)
    })

        return () => {
            
        };
    }, []);
    return(
        <div>

            <div className="footer">
                    <div className="footer_box">

                        <div className='footer_bot'>
                            友情链接
                        </div>

                        <div className='footer_ljie'>
                            {
                                arr.map((item:any,index)=>{
                                    return(
                                            <div className='footer_ljie_item'>
                                                <a target="_blank" style={{color:"white"}} href={item.keyword}>{item.simple_desc}</a>
                                             </div>
                          
                                    )
                                })
                            }
                        </div>

                        <hr />

                       <div className='bot_box'>
                       <div>
                            主办单位：{list.comepany_name&&list.comepany_name.value}
                        </div>
                        
                        <div>
                            联系电话：{list.phone&&list.phone.value}
                        </div>
                        <div>
                            备案号： <span>{list.record&&list.record.value}</span>
                        </div>
                        <div>
                            运营单位：{list.company_manage&&list.company_manage.value}
                        </div>
                        <div>
                            <span className="footer_t3"> 地址：{list.address&&list.address.value}</span> 
                        </div>
                       
                       </div>


                        {/* <div className="icp_box">
                        <img src={icp} alt="" className="icp" />{list.record&&list.record.value}
                        </div> */}

                    </div>

            </div>

        </div>
    )
}