import { Button, Result } from "antd";
import "./index.css"
import { useNavigate } from "react-router-dom";

export default function App(){
    const navigate = useNavigate()
    return(
        <div>

            <div className="f404">
            <Result
          icon={
            <img
              width={256}
              src="https://gw.alipayobjects.com/zos/antfincdn/zIgkN%26mpMZ/shibaizhuangtaizuo.png"
            />
          }
          style={{
            height: '100%',
            background: '#fff',
          }}
          title="页面不存在!!!"
          extra={
            <>
              <div
                style={{
                  maxWidth: 620,
                  textAlign: 'start',
                  backgroundColor: 'rgba(255,229,100,0.3)',
                  borderInlineStartColor: '#ffe564',
                  borderInlineStartWidth: '9px',
                  borderInlineStartStyle: 'solid',
                  padding: '20px 45px 20px 26px',
                  margin: 'auto',
                  marginBlockEnd: '30px',
                  marginBlockStart: '20px',
                }}
              >
                <p>注意</p>
                <p>
                 您访问的页面地址错误或页面已丢失，请检查后重试。
                </p>
                <ul
                  style={{
                    listStyle: 'none',
                  }}
                >
                  <li>
                    事件处理（
                    <a href="#">
                      了解更多
                    </a>
                    ）
                  </li>
                </ul>
              </div>
              <Button
                danger
                type="primary"
                onClick={() => {
                    navigate("/index")
                }}
              >
                返回首页
              </Button>
            </>
          }
        />

            </div>

        </div>
    )
}