import http from "../utils/axios"


//获取学员信息
let GetNew = (data:any)=>http({url:'server/v1/spot_check',method:'POST',data})

//获取学员小节不通过记录
let GetSectionUnCheck = (data:any)=>http({url:'server/v1/spot_unchecks',method:'POST',data})

export{
    GetNew,
    GetSectionUnCheck
}