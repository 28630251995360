import {legacy_createStore as createStore,combineReducers,applyMiddleware} from "redux"
import Thunk,{ ThunkMiddleware }  from "redux-thunk"

let reducer = combineReducers({
    
})


// @ts-ignore  // @ts-ignore注释，告诉TypeScript在特定的行上忽略错误。
let store = createStore(reducer,applyMiddleware())

export default store

