export const second=(value)=> {

    let theTime = parseInt(value);// 秒
    let middle= 0;// 分
    let hour= 0;// 小时
  
    if(theTime > 60) {
        middle= parseInt(theTime/60);
        theTime = parseInt(theTime%60);
        if(middle> 60) {
            hour= parseInt(middle/60);
            middle= parseInt(middle%60);
        }
    }
    if(parseInt(theTime)<10){
      theTime='0'+parseInt(theTime)
    }else {
      theTime=parseInt(theTime)
    }
    let result = ""+theTime+"";
    if(middle > 0) {
      if(parseInt(middle)<10){
        result = "0"+parseInt(middle)+":"+result;
      }else{
        result = ""+parseInt(middle)+":"+result;
      }
       
    }else{
      result = "00"+":"+result;
    }
    if(hour> 0) {
        result = ""+parseInt(hour)+":"+result;
    }else{
      result = "0"+":"+result;
    }
    return result;
  }