import {Navigate} from "react-router-dom"


export default function Author(props:any):JSX.Element{

    let token = localStorage.getItem("token")
    
    if(!token){
        return (
            <Navigate to={`/index`}></Navigate>
        )
    }else{
        return (
            <div>
                {props.OldComponent}
            </div>
        )
    }
}

//路由鉴权

